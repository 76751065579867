import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../core/models/ApiResponse';

@Injectable({
	providedIn: 'root'
})
export class WorkflowService {
	baseURL: string = `${environment.baseService}${environment.services.cord}`;

	constructor(private http: HttpClient) {
	}


	getFormDetails(formId?: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/forms/${formId}`);
	}

	getFormVersionDetails(formId?: number, formVersionId?: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/forms/${formId}/versions/${formVersionId}`);
	}

	getSubmissionDetailsById(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}`);
	}

	getAllSubmissionDetailsBySequence(sequence: string): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/all/@${sequence}`);
	}

	getSubmissionDetailsBySequence(sequence: string): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/@${sequence}`);
	}

	updateSubmissionData(submissionId: number, data: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}`, {data});
	}

	completeStep(submissionId: number, data?: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/completeStep`, data);
	}

	completeSubmission(submissionId: number, data?: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/submit`, data);
	}

	validateStep(submissionId: number, currentStep: number, data?: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/checkStep/${currentStep}`, data);
	}

	uploadSubmissionAttachment(submissionId: number, data: any, uuid: string) {
		let dto = {
			'fileName': data?.name,
			'fileSize': data?.size
		};
		// TODO: multipart file upload
		// const httpOptions = {
		// 	headers: new HttpHeaders({
		// 		"Content-Type": "multipart/form-data"
		// 	})
		// };
		return this.http.post<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/files/${uuid}`, dto);
	}

	getSubmissionAttachments(submissionId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/files`);
	}

	downloadAllSubmissionAttachments(submissionId: number): Observable<Blob> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			responseType: 'blob'
		});
		return this.http.get<Blob>(`${this.baseURL}/submissions/${submissionId}/files/all`, {
			headers,
			responseType: 'blob' as 'json'
		});
	}

	downloadSubmissionAttachment(submissionId: number, fileKey: string, fileIndex: number): Observable<ApiResponse> {
		const headers = new HttpHeaders({
			Accept: 'application/json'
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/files/${fileKey}/${fileIndex}`, {
			headers
		});
	}

	deleteSubmissionAttachment(submissionId: number, fileKey: string, data: any, fileIndex: number) {
		return this.http.delete<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/files/${fileKey}/${fileIndex}`, data);
	}

	// Get reviewers by submission ID
	getReviewerBySubmission(submissionId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/reviewers`);
	}

	// Assign reviewer to submission
	assignReviewersBySubmission(submissionId: number, dto: any) {
		return this.http.post<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/assign`, dto);
	}

	// Get list of submissions related to any form
	getAllSubmissions(dto: any) {
		let queryParams = new HttpParams();
		Object.keys(dto).forEach((key) => {
			if (dto[key] !== null || dto[key] !== undefined)
				queryParams.append(key, dto[key]);
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/forms/submissions`, {params: dto});
	}

	// Get own list of review related to any submission
	getAssignedSubmissions() {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/forms/submissions/review`);
	}

	getFormIdQueue(formId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/forms/${formId}/queue`);
	}

	getSubmissionsReviews(submissionId: number, submissionStatusId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/status/${submissionStatusId}/reviews`);
	}

	getReviewData(reviewId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/reviews/${reviewId}`);
	}

	updateReviewData(reviewId: number, data: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reviews/${reviewId}`, {data});
	}

	completeStepReview(reviewId: number, data?: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reviews/${reviewId}/completeStep`, data);
	}

	completeReview(reviewId: number, data?: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reviews/${reviewId}/submit`, data);
	}

	unSubmitReview(reviewId: number): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reviews/${reviewId}/unSubmit`, {});
	}

	createReview(submissionId: number, submissionStatusId: number, userIds: number[]) {
		return this.http.post<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/status/${submissionStatusId}/review`, {}, this.setParams({userIds: userIds?.length > 0 ? userIds : null}));
	}

	// Queues
	getViewsByQueue(queueId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueId}/views`);
	}
	getResultView(queueId: number, viewId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueId}/views/${viewId}/result`);
	}

	//Change Requests
	getChangeRequestsBySubmission(submissionId: number, query?: { active: boolean, completed: boolean }): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests`, this.setParams(query));
	}

	createNewChangeRequest(submissionId: number, dto: any) {
		return this.http.post<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests`, dto);
	}

	getChangeRequestDetailsByID(submissionId: number, changeRequestId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests/${changeRequestId}`);
	}

	updateChangeRequest(submissionId: number, changeRequestId: number, dto: any): Observable<ApiResponse> {
		return this.http.put<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests/${changeRequestId}`, dto);
	}

	markAsCompletedChangeRequest(submissionId: any, changeRequestId: number): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests/${changeRequestId}/complete`, {});
	}

	markAsIncompleteChangeRequest(submissionId: number, changeRequestId: number): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests/${changeRequestId}/incomplete`, {});
	}

	deleteChangeRequest(submissionId: number, changeRequestId: number) {
		return this.http.delete<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/changeRequests/${changeRequestId}`);
	}

	getDefinitionFromQueueSubmissionStatus(queueSubmissionStatusId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queueSubmissionStatuses/${queueSubmissionStatusId}`);
	}


	setParams(params: any) {
		let queryParams = new HttpParams();
		if (params != null || params != undefined) {
			Object.keys(params).forEach((keyParam) => {
				if (params[keyParam] != null || params[keyParam] != undefined)
					queryParams = queryParams.append(keyParam, params[keyParam]);
			});
		}
		return {params: queryParams};
	}

	getQueuesByNetworkId(networkId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/networks/${networkId}/queues`);
	}

	getQueueSubmissionStatuses(queueSubmissionStatusId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queueSubmissionStatuses/${queueSubmissionStatusId}`);
	}

	getSubmissionByQueue(queueuId: number, submissionId?: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueuId}/reviews`, this.setParams({submissionId: submissionId}));
	}

	getQueueDetails(queueId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueId}`);
	}

	updateStatusByQueueId(submissionId: number, submissionStatusId: number, queueStatusId: number, userIds: number[]): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/status/${submissionStatusId}/changeStatus/${queueStatusId}`, {}, this.setParams({userIds: userIds?.length > 0 ? userIds : null}));
	}

	getResultViewScoring(queueId: number, viewId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueId}/views/${viewId}/reviews`);
	}

	getTechnicalReviewScoring(queueId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/${queueId}/views/technicalReview/reviews`);
	}

	getReviewsBySubmissionAndStatusId(submissionId: number, submissionStatusId: number) {
		return this.http.get<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/status/${submissionStatusId}/reviews`);
	}

	deleteReview(submissionId: number, submissionStatusId: number, reviewId: number) {
		return this.http.delete<ApiResponse>(`${this.baseURL}/submissions/${submissionId}/status/${submissionStatusId}/review/${reviewId}`);
	}

	getNetworksByActiveQueues() {
		return this.http.get<ApiResponse>(`${this.baseURL}/queues/networks`);
	}
}
