import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { AuthService } from '../../auth/auth.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import posthog from 'posthog-js';
import { SysOrg } from '../../models/System.model';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import isDefined from '../../utils/isDefined';
import { UtilsService } from '../../../services/utils.service';

export interface MenuItem {
	id: number | null;
	alwaysShow?: boolean;
	label: string;
	key: string | null;
	path: string;
	active: boolean;
	idModule: number | null;
	idAction?: number | null;
	children?: MenuItem[];
	show?: Function;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	orgArray: SysOrg[] = [];
	selectedOrg: SysOrg | null = null;

	lang = 'en';
	permissions: number[] = [];
	actions: number[] = [];
	userName: string = '';

	//new feature
	header_modules: any[] = [];

	// Feature flags
	accountProfileEnabled: boolean = false;
	geovizEnabled: boolean = false;

	private destroy$: Subject<void> = new Subject<void>();

	menuItems: MenuItem[] = [];

	menuItemsAll: MenuItem[] = [
		{
			id: 1,
			alwaysShow: true,
			key: 'home',
			label: 'nav.home',
			path: '/',
			active: true,
			idModule: null,
			children: []
		},
		{
			id: 2,
			key: 'reports',
			label: 'nav.reports',
			path: '/ops/submissions',
			active: false,
			idModule: 20,
			children: []
		},
		{
			id: null,
			key: 'menu_networks',
			label: 'nav.networks',
			path: '#',
			active: false,
			idModule: null,
			children: [
				{
					id: 3,
					key: 'network_applications',
					label: 'My Applications',
					path: '/networks/applications',
					active: false,
					idModule: 19,
					children: []
				},
				{
					id: 4,
					key: 'network_submissions',
					label: 'Submissions',
					path: '/networks/submissions',
					active: false,
					idModule: null,
					idAction: 109,
					children: []
				},
				{
					id: 5,
					key: 'network_profile',
					label: 'nav.network_profile',
					path: '/networks/profile',
					active: false,
					idModule: null,
					idAction: 141, //Todo: Check multiple actions OR
					children: []
				}
			]
		},
		{
			id: null,
			key: 'menu_analytics',
			label: 'nav.analytics',
			path: '#',
			active: false,
			idModule: null,
			children: [
				{
					id: 6,
					key: 'analytics_compliance',
					label: 'nav.compliance',
					path: '/analytics/compliance',
					active: false,
					idModule: 14,
					children: []
				},
				{
					id: 7,
					key: 'analytics_geoviz',
					label: 'nav.geoviz',
					path: '/analytics/geoviz',
					active: false,
					idModule: 15,
					children: [],
					// show: () => this.geovizEnabled
				},
				{
					id: 8,
					key: 'analytics_metrics',
					label: 'nav.metrics',
					path: '/analytics/metrics',
					active: false,
					idModule: 17,
					children: []
				},
				{
					id: 21,
					key: 'analytics_calendar',
					label: 'nav.calendar',
					path: '/analytics/calendar',
					active: false,
					idModule: 13,
					children: []
				},
			]
		},
		{
			id: null,
			key: 'menu_admin',
			label: 'nav.admin',
			path: '#',
			active: false,
			idModule: null,
			children: [
				{
					id: 9,
					key: 'admin_dam',
					label: 'nav.dam',
					path: '/admin/dam',
					active: false,
					idModule: 24,
					children: []
				},
				{
					id: 10,
					key: 'admin_hubs',
					label: 'nav.hubs',
					path: '/admin/hubs',
					active: false,
					idModule: 16,
					children: []
				},
				{
					id: 11,
					key: 'admin_networks',
					label: 'Networks',
					path: '/networks/admin',
					active: false,
					idModule: 7,
					children: []
				},
				{
					id: 12,
					key: 'admin_reports',
					label: 'nav.report-mgmt',
					path: '/admin/reports',
					active: false,
					idModule: 26,
					children: []
				},
				{
					id: 14,
					key: 'admin_org_users',
					label: 'nav.org_user_management',
					path: '/admin/users',
					active: false,
					idModule: 27,
					children: []
				},
				{
					id: 22,
					key: 'admin_calendar',
					label: 'nav.calendar',
					path: '/admin/calendar',
					active: false,
					idModule: 2,
					children: []
				},
			]
		},
		{
			id: null,
			key: 'menu_workflow',
			label: 'nav.workflow',
			path: '#',
			active: false,
			idModule: 23,
			children: [
				{
					id: 15,
					key: 'workflow_queues',
					label: 'Queue',
					path: '/wf/queue-view',
					active: false,
					idModule: null,
					idAction: 310,
					children: []
				},
				{
					id: 16,
					key: 'workflow_reviews',
					label: 'nav.review_view',
					path: '/wf/reviewer-view',
					active: false,
					idModule: null,
					idAction: 321,
					children: []
				},
			]
		},
		{
			id: 17,
			key: 'dal',
			label: 'nav.dal',
			path: '/dal',
			active: false,
			idModule: 25,
			children: []
		},
		{
			id: 18,
			key: 'sys',
			label: 'nav.sys',
			path: '#',
			active: false,
			idModule: null,
			children: [
				{
					id: 13,
					key: 'sys_users',
					label: 'nav.sys_users',
					path: '/sys/users',
					active: false,
					idModule: 11,
					children: []
				},
				{
					id: 19,
					key: 'sys_orgs',
					label: 'nav.sys_orgs',
					path: '/sys/orgs',
					active: false,
					idModule: null,
					children: []
				},
				{
					id: 20,
					key: 'sys_roles',
					label: 'nav.sys_roles',
					path: '/sys/roles',
					active: false,
					idModule: null,
					children: []
				},
				{
					id: 23,
					key: 'sys_join_request',
					label: 'nav.sys_join_request',
					path: '/sys/join-request',
					active: false,
					idModule: null,
					children: []
				}
			]
		}
	];

	menuItemsStart: MenuItem[] = [
		{
			id: 0,
			key: 'onboarding',
			label: 'nav.account_setup',
			path: '/onboarding',
			active: true,
			idModule: null,
			children: []
		},
	];

	interval: any;

	constructor(
		private layoutService: LayoutService,
		private authService: AuthService,
		private permissionsService: PermissionsService,
		private profile: ProfileService,
		private router: Router,
		private utilsService: UtilsService
	) {
	}

	ngOnInit() {
		this.listenUserName();
		this.listenMediaQueryChange();
		this.listenUseAvatarMenuClick();

		const orgs = this.utilsService.toJSON(localStorage.getItem('CORD-ORG-ARRAY') || '[]') || [];

		// Get list of orgs available for the current user
		if (orgs.length > 0) {
			this.menuItems = this.menuItemsAll;

			// Store available orgs
			this.orgArray = orgs;
			//Initialize in BaseLayout
			// localStorage.setItem('CORD-ORG-ARRAY', JSON.stringify(this.orgArray));

			// Check if selected org already exists
			if (localStorage.getItem('CORD-ORG') !== null) { // Selected org exists
				// @ts-ignore
				const temp = JSON.parse(localStorage.getItem('CORD-ORG'));
				this.selectedOrg = this.orgArray.find(org => org.id === temp.id) || this.orgArray[0];
				// console.log('reading selected org', this.selectedOrg);
			}

			// Auto-select first available org
			else {
				this.selectedOrg = this.orgArray[0];
				console.log('auto selected org', this.selectedOrg);
			}

			// Update selected org
			localStorage.setItem('CORD-ORG', JSON.stringify(this.selectedOrg));

			// Initial fetch for permissions
			this.getPermissions(false, true);
			this.getOrgs();

			// Set getPermissions for every 5 minutes
			this.interval = setInterval(() => {
				this.getPermissions(false, true);
				this.getOrgs();
			}, 300 * 1000);
		} else {
			localStorage.removeItem('CORD-ORG');
			localStorage.removeItem('CORD-ORG-ARRAY');
			localStorage.removeItem('CORD-ORG-DISPLAY');
			console.error('No organizations available');
			this.menuItems = this.menuItemsStart;
		}

		// Wait for feature flags to be available
		posthog.onFeatureFlags(() => {
			// Get whether access to user's account profile is enabled
			this.accountProfileEnabled = posthog.isFeatureEnabled('my-account', {send_event: false}) || false;
			this.geovizEnabled = posthog.isFeatureEnabled('geoviz', {send_event: false}) || false;
			if (this.geovizEnabled) this.setMenu();
		});
	}

	listenUserName() {
		this.authService.userInfo()
			.pipe(takeUntil(this.destroy$))
			.subscribe((user) => {
				this.userName = user.fullName;
			});
	}

	listenUseAvatarMenuClick() {
		// on User avatar click handler
		/*this.menuService.onItemClick()
			.pipe(
				filter(({tag}) => tag === 'user-menu'),
				// Nebular types don't allow adding more properties to the menu array objects, so we need to ignore typings to make it work
				// thinking about translation keys in the future when title is variable
				// @ts-ignore
				map(({item: {title, action}}) => ({title, action})),
			)
			.subscribe(({action}) => {
				if (action === "logout") {
					this.authService.logout();
				}
			});*/
	}

	listenMediaQueryChange() {
		/*const {xl} = this.breakpointService.getBreakpointsMap();
		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);*/
	}

	ngOnDestroy() {
		//Stops getPermissions interval
		clearInterval(this.interval);

		// prevents memory leaking
		this.destroy$.next();
		this.destroy$.complete();

	}

	toggleSidebar(): boolean {
		// this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		// this.menuService.navigateHome("menu");
		return false;
	}

	signOut() {
		this.authService.logout();
	}

	getOrgs() {
		this.profile.getMyOrgs().subscribe({
			next: ({data, success, message}) => {
				if (success) {
					const hasOrg = data.orgs.length > 0;
					localStorage.setItem('CORD-ORG-ARRAY', JSON.stringify(data.orgs || []));
					if (hasOrg && !isDefined(localStorage.getItem('CORD-ORG'))) {
						// Update selected org
						localStorage.setItem('CORD-ORG', JSON.stringify(data.orgs[0]));
						// Initial load for User-Org settings
						this.getUserOrgConfig();
					}
				} else console.error('Couldn\'t get organizations', message);
			},
			error: (err) => {
				console.error('Couldn\'t get organizations', err);
			},
		});
	}

	getPermissions(reload = false, service = false) {
		this.permissions = [];
		this.actions = [];
		this.header_modules = [];

		if (service) {
			console.log('Getting permissions...');
			this.permissionsService.getMyPermissions().subscribe({
				next: ({success, data}) => {
					if (success) {
						this.permissions = data?.modules || [];
						this.actions = data?.actions || [];

						data?.configs?.forEach((rol: any) => {
							this.header_modules = [
								...this.header_modules,
								...(rol.config?.menu || [])?.filter((key: any) => {
									return !this.header_modules.includes(key);
								}) || []
							];
						});

						localStorage.setItem('CORD-MODULES', JSON.stringify(this.permissions));
						localStorage.setItem('CORD-ACTIONS', JSON.stringify(this.actions));
						localStorage.setItem('CORD-MENU', JSON.stringify(this.header_modules));

						this.setMenu();

						if (reload) {
							const currentUrl = this.router.url;
							this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
								this.router.navigateByUrl(currentUrl).then();
							});
						}
					}
				}, error: (error) => {
					console.error(`Something went wrong with the server trying to get permissions [${error}]`);
					this.setMenu();
				}
			});
		} else {
			this.permissions = this.utilsService.toJSON(localStorage.getItem('CORD-MODULES') || '[]');
			this.actions = this.utilsService.toJSON(localStorage.getItem('CORD-ACTIONS'));
			this.header_modules = this.utilsService.toJSON(localStorage.getItem('CORD-MENU'));

			if (reload) {
				const currentUrl = this.router.url;
				this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
					this.router.navigateByUrl(currentUrl).then();
				});
			}

			this.setMenu();
		}
	}

	getUserOrgConfig() {
		this.profile.getOrgConfig().subscribe({
			next: (res) => {
				if (res.success) {
					if (isDefined(res.data?.orgNameDisplay))
						localStorage.setItem('CORD-ORG-DISPLAY', res.data?.orgNameDisplay);
				} else {
					console.error('Couldn\'t obtain user config', res.message);
				}
			},
			error: (err) => {
				console.error('Couldn\'t obtain user config', err);
			}
		});
	}

	setMenu() {
		this.menuItems.forEach((item: MenuItem) => {
			const children = item.children || [];

			if (children.length !== 0) {
				children.forEach((child: MenuItem) => {
					child.active = !child.alwaysShow ? this.header_modules.includes(child.key) : true;
					// child.active = (child.idModule !== undefined && child.idModule !== null) ? this.permissions.includes(child.idModule) : (!!child.idAction) ? this.actions.includes(child.idAction) : true;
				});

				item.active = children.filter(child => child.active).length > 0;
			} else {
				item.active = !item.alwaysShow ? this.header_modules.includes(item.key) : true;
				// item.active = !!item.idModule ? this.permissions.includes(item.idModule) : (!!item.idAction) ? this.actions.includes(item.idAction) : true;
			}
		});
	}

	selectOrg(org: SysOrg, event: MouseEvent) {
		event.preventDefault();
		this.selectedOrg = org;

		console.log('Org Switch', this.selectedOrg);
		localStorage.setItem('CORD-ORG', JSON.stringify(this.selectedOrg));

		// Stop pending page requests before reloading permissions
		window.stop();

		// Reload permissions
		this.getPermissions(true, true);

		//Get User-Org Config
		this.getUserOrgConfig();
	}
}
