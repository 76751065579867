import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../core/models/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class ReportSubmissionServiceService {
	baseURL: string = `${environment.baseService}${environment.services.cord}`;

	constructor(private http: HttpClient) {
	}

	// Create Report Activity associated to a Report Submission ID
	createSubmissionActivity(submissionId: number, payload: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities`, payload);
	}

	// Save report activity
	saveReportActivity(submissionId: number, activityId: any, payload: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityId}`, payload);
	}

	// Cancel Report Submission
	cancelReportSubmission(submissionId: number, payload: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/cancel`, payload);
	}

	// Cancel Unsubmitted Report Submission
	cancelUnsubmittedReportSubmission(submissionId: number, payload: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/unsubmitted/cancel`, payload);
	}

	// Create report submission
	createReportSubmission(payload: any) {
		return this.http.post<ApiResponse>(`${this.baseURL}/reports/submissions`, payload);
	}

	// Copy activities and data of submission cancelled
	createSubmissionFromGivenCancelledSubmissionID(submissionId: any): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/copyActivity`, {});
	}

	// Get a list of submission cancelled by report period ID
	getCancelledReportSubmissions(reportId: number, periodId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/periods/${periodId}/cancelled`, {params: this.setParams({reportId: reportId})});
	}

	// Submit status report submission by ID
	submitReportSubmission(submissionId: any, payload = null): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/submit`, payload);
	}

	// Update status report submission by ID
	updateReportSubmissionData(submissionId: number, payload: any): Observable<ApiResponse> {
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}`, payload);
	}

	// Get report submission by ID
	getReportSubmission(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}`);
	}

	// Get list of activities for a given Report Submission ID
	getActivitiesListByReportSubmission(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities`);
	}

	// Get report activity by ID
	getReportActivity(submissionId: number, activityId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityId}`);
	}

	// Get submission summary by ID
	getSubmissionSummary(submissionId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/summary`);
	}

	// Get report details by ID
	getReportDetailsById(reportId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/${reportId}`);
	}

	// Get cancellation reason by report id
	getCancellationReason(reportId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/${reportId}/cancellationReasons`);
	}

	// Delete a report submission for a given ID
	deleteSubmission(submissionId: any): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}`);
	}

	// Delete activity for a given ID
	deleteActivity(submissionId: number, activityIds: number[]): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityIds.join(',')}`);
	}

	getActivityTypes(reportId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/${reportId}/activityTypes`);
	}

	getCampaignsByReport(reportId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/${reportId}/campaigns`);
	}

	getCampaignsSelectedByActivityId(submissionId: number, activityId: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityId}/campaigns`);
	}

	selectCampaignByActivityId(submissionId: number, activityId: number, campaignId: number): Observable<ApiResponse> {
		return this.http.post<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityId}/campaigns/${campaignId}`, {});
	}

	deleteCampaignByActivityId(submissionId: number, activityId: number, campaignId: number): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/activities/${activityId}/campaigns/${campaignId}`);
	}


	// ------------------------------- FILES -------------------------------

	// Save files for a report submission
	uploadFile(submissionId: any, fileId: number, payload: any): Observable<ApiResponse> {
		let dto = {
			'fileName': payload?.name,
			'fileSize': payload?.size
		};
		return this.http.patch<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/files/${fileId}`, dto);
	}

	downloadFile(submissionId: any, fileId: number, fileIndex: number): Observable<any> {
		const headers = new HttpHeaders({
			'Accept': 'application/json',
		});
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/files/${fileId}/${fileIndex}`, {
			headers,
		});
	}

	deleteFile(submissionId: any, fileId: number, fileIndex: number): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/files/${fileId}/${fileIndex}`);
	}

	getAllFilesBySubmissionId(submissionId: any): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${this.baseURL}/reports/submissions/${submissionId}/files`);
	}

	setParams(params: any) {
		let queryParams = new HttpParams();
		if (params != null || params != undefined) {
			Object.keys(params).forEach((keyParam) => {
				if (params[keyParam] != null || params[keyParam] != undefined)
					queryParams = queryParams.append(keyParam, params[keyParam]);
			});
		}
		return queryParams;
	}
}
