import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowService } from 'src/app/modules/workflow/workflow.service';
import Swal from 'sweetalert2';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MatTableDataSource } from '@angular/material/table';
import { ReportSubmissionServiceService } from '../../../services/report-submission-service.service';
import { UtilsService } from '../../../services/utils.service';
import { ReportMgmtService } from '../../../modules/report-mgmt/report-mgmt.service';
import isDefined from '../../../core/utils/isDefined';
import getIndex from '../dynamic-form/models/question-model';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
	dropFile = false;
	files: NgxFileDropEntry[] = [];
	i = 0;
	fileData: any = [];
	dataSource: any = [];
	displayedColumns: string[] = ['name', 'action'];
	@Input() submissionId: any;
	@Input() readonly: boolean = false;
	@Input() definition: any;
	@Input() delete: boolean = true;
	@Input() filesInput: string[] = [];
	@Input() fileKey: string = '';
	@Input() module: 'wfm' | 'omg' = 'wfm';
	@Input() isAdmin: boolean = false;

	//For repeated inner elements
	@Input() indexElement: number | null = null;

	@Output() filesOutput = new EventEmitter<Object>();

	defaultExtensionsAllowed = '';//['.pdf','.doc','.docx','.xls', '.xlsx']
	maxDefaultSize = 30000000; //30MB

	constructor(private workflowService: WorkflowService,
				private reportSubmissionService: ReportSubmissionServiceService,
				private reportMgmtService: ReportMgmtService,
				private utilsService: UtilsService,
				private http: HttpClient) {
	}

	ngOnInit(): void {
		if (this.submissionId) {
			this.getFilesList();
		}
	}

	getFilesList() {
		this.actionModule(this.module, 'getFiles');
	}

	dropped(files: NgxFileDropEntry[]) {
		if (!this.definition.multipleFiles && (files.length > 1 || this.files.length > 1)) {
			Swal.fire({
				icon: 'warning',
				text: 'You just can upload one file.'
			});
		} else {
			this.files = files;
			for (let droppedFile of files) {
				// Is it a file?
				if (
					droppedFile.fileEntry.isFile &&
					this.isFileAllowed(droppedFile.fileEntry.name)
				) {
					const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
					fileEntry.file((file: File) => {
						if (this.isFileSizeAllowed(file.size)) {
							this.showLoading('Uploading...');
							// Here you can access the real file
							var reader = new FileReader();
							reader.readAsDataURL(file);
							reader.onloadend = (e) => {
								const data = new FormData();
								data.append('file', file);

								this.actionModule(this.module, 'upload', {data: data, file: file});
							};
						} else {
							Swal.fire({
								title: `Upload Error`,
								icon: 'error',
								text: `Max size of a file allowed is ${(this.definition?.maxSize || this.maxDefaultSize) / 1000000}MB`,
								heightAuto: false,
							});
						}
					});
				} else {
					Swal.fire({
						title: `Upload Error`,
						icon: 'error',
						text: `File extension is not allowed. Allowed extensions are (${this.definition?.extensionsAllowed})`,
						heightAuto: false,
					});
				}
			}
		}
	}

	isFileAllowed(fileName: string) {
		if (this.definition?.extensionsAllowed?.length > 0) {
			let isFileAllowed = false;
			const allowedFiles = this.definition?.extensionsAllowed.map((extension: string) => {
				return extension.toUpperCase();
			}) || [];
			const regex = /(?:\.([^.]+))?$/;
			const extension = regex.exec(fileName.toUpperCase());
			if (undefined !== extension && null !== extension) {
				for (const ext of allowedFiles) {
					if (ext === extension[0]) {
						isFileAllowed = true;
					}
				}
			}
			return isFileAllowed;
		} else {
			return true;
		}
	}

	isFileSizeAllowed(size: any) {
		const MAX_SIZE = this.definition?.maxSize || this.maxDefaultSize;
		return size < MAX_SIZE;
	}

	deleteFile(submissionId: number, fileKey: string, fileIndex: number) {
		const data = {}
		Swal.fire({
			title: 'Confirm Deletion',
			text: 'Would you like to delete this file?',
			icon: 'warning',
			// iconColor: '#d87926',
			showCancelButton: true,
			// confirmButtonColor: '#0973B0',
			// cancelButtonColor: '#dc3545',
			confirmButtonText: 'Delete',
			heightAuto: false,
		}).then((result) => {
			if (result.isConfirmed) {
				this.showLoading(`Deleting file...`);

				this.actionModule(this.module, 'delete', {fileKey: fileKey, fileIndex: fileIndex, data: data});
			}
		});
	}

	downloadFile(submissionId: number, fileKey: string, fileIndex: number, fileName: string, fileType: string) {
		const data = {};
		this.actionModule(this.module, 'download', {fileKey: fileKey, fileIndex: fileIndex, data: data, fileName: fileName, fileType: fileType});
	}

	fileOver(event: any) {
		if (event) {
			this.dropFile = true;
		}
	}

	fileLeave(event: any) {
		if (event) {
			this.dropFile = false;
		}
	}

	showLoading(message: string) {
		Swal.fire({
			title: `${message}`,
			timerProgressBar: true,
			showCancelButton: false,
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			heightAuto: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
	}

	actionModule(module: string, action: string, data?: any) {
		switch (module) {
			case 'omg':
				switch (action) {
					case 'getFiles':
						if (this.isAdmin)
							this.getFiles_OMG_Admin();
						else
							this.getFiles_OMG();
						break;
					case 'upload':
						this.uploadFile_OMG(data);
						break;
					case 'delete':
						this.deleteFile_OMG(data);
						break;
					case 'download':
						if (this.isAdmin)
							this.downloadFile_OMG_Admin(data);
						else
							this.downloadFile_OMG(data);
						break;
				}
				break;
			default:
				switch (action) {
					case 'getFiles':
						this.getFiles_WFM();
						break;
					case 'upload':
						this.uploadFile_WFM(data);
						break;
					case 'delete':
						this.deleteFile_WFM(data);
						break;
					case 'download':
						this.downloadFile_WFM(data);
						break;
				}
				break;
		}
	}


	// -------------------------------- Services --------------------------------

	//WFM

	getFiles_WFM() {
		this.workflowService.getSubmissionAttachments(this.submissionId).subscribe((result: any) => {
			if (result.success) {
				if (result.data[this.fileKey]) {
					for (let i = 0; i < result.data[this.fileKey].length; i++) {
						this.fileData[i] = result.data[this.fileKey][i].key;
					}
					setTimeout(() => {
						this.i = this.fileData.length;
						// this.filesOutput.emit(this.fileData);
						this.dataSource = new MatTableDataSource(this.fileData);
					}, 50);
				}
			} else {
				Swal.fire({
					title: 'Something went wrong',
					text: `Something went wrong trying to get the attachments [${result.message}], please reload or try again.`,
					icon: 'error',
				});
			}
		}, (error) => {
			console.error(`Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`);
			Swal.fire({
				icon: 'error',
				title: 'Something went wrong',
				text: `Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`,
			});
		});
	}

	uploadFile_WFM(payloadData: any) {
		this.workflowService
			.uploadSubmissionAttachment(this.submissionId, payloadData.file, this.definition.uuid)
			.subscribe({
				next: (result: any) => {
					Swal.close();
					if (result.success) {
						//Upload file to pre-signed url for S3
						this.http.put(result.data.url, payloadData.data).subscribe({
							next: (res_file: any) => {

								// this.fileData[this.i] = result.data.fileName;
								this.fileData[this.i] = payloadData.file.name;
								this.dataSource = new MatTableDataSource(this.fileData);
								this.i += 1;
								//Event Output
								this.filesOutput.emit(this.fileData);
								console.log('Uploaded File(?)', res_file);
							},
							error: (error_file: any) => {
								console.error('Couldn\'t upload file', error_file);
							}
						});
					} else {
						Swal.fire({
							title: 'Something went wrong',
							text: `Something went wrong trying to upload the attachment [${result.message}], please reload or try again.`,
							icon: 'error',
							heightAuto: false,
						});
					}
				}, error: (error) => {
					console.error(`Something went wrong with the server trying to upload the attachment [${error}], please reload or try again.`);
					Swal.fire({
						icon: 'error',
						title: 'Something went wrong',
						text: `Something went wrong with the server trying to upload the attachment [${error?.error?.message}], please reload or try again.`,
						heightAuto: false,
					});
				}
			});
	}

	deleteFile_WFM(payloadData: any) {
		this.workflowService.deleteSubmissionAttachment(this.submissionId, payloadData.fileKey, payloadData.data, payloadData.fileIndex).subscribe({
			next: (result: any) => {
				if (result.success) {
					Swal.close();
					this.fileData.splice(payloadData.fileIndex, 1);
					this.i -= 1;
					this.dataSource = new MatTableDataSource(this.fileData);
				} else {
					Swal.close();
					Swal.fire({
						title: 'Something went wrong',
						text: `Something went wrong trying to delete the attachment [${result.message}], please reload or try again.`,
						icon: 'error',
						heightAuto: false,
					});
				}
			},
			error: (error) => {
				console.error(`Something went wrong with the server trying to delete the attachment [${error?.error?.message}], please reload or try again.`);
				Swal.fire({
					icon: 'error',
					title: 'Something went wrong',
					text: `Something went wrong with the server trying to delete the attachment [${error?.error?.message}], please reload or try again.`,
					heightAuto: false,
				});
			}
		});
	}

	downloadFile_WFM(payloadData: any) {
		this.workflowService.downloadSubmissionAttachment(this.submissionId, payloadData.fileKey, payloadData.fileIndex).subscribe((result: any) => {
			if (result) {
				let openFile = document.createElement('a');
				openFile.href = result?.data;
				openFile.target = '_blank';
				openFile.click();
				setTimeout(() => {
					openFile.remove();
				}, 100);

				//Don't need to process file since it comes directly from the url
				// this.processFileDownload(payloadData, result);
			} else {
				this.utilsService.errorAlert('Couldn\'t obtain file');
			}
		}, (error) => {
			this.utilsService.errorAlert(null, error);
		});
	}


	//OMG
	getFiles_OMG() {
		this.reportSubmissionService.getAllFilesBySubmissionId(this.submissionId).subscribe({
			next: (result: any) => {
				if (result.success) {
					if (result.data[this.fileKey]) {
						for (let i = 0; i < result.data[this.fileKey].length; i++) {
							this.fileData[i] = result.data[this.fileKey][i].key.split('/').pop();
						}
						setTimeout(() => {
							this.i = this.fileData.length;
							// this.filesOutput.emit(this.fileData);
							this.dataSource = new MatTableDataSource(this.fileData);
						}, 50);
					}
				} else {
					Swal.fire({
						title: 'Something went wrong',
						text: `Something went wrong trying to get the attachments [${result.message}], please reload or try again.`,
						icon: 'error',
					});
				}
			},
			error: (error) => {
				console.error(`Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`);
				Swal.fire({
					icon: 'error',
					title: 'Something went wrong',
					text: `Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`,
				});
			}
		});
	}

	getFiles_OMG_Admin() {
		this.reportMgmtService.getAllFilesBySubmissionId(this.submissionId).subscribe({
			next: (result: any) => {
				if (result.success) {
					if (result.data[this.fileKey]) {
						for (let i = 0; i < result.data[this.fileKey].length; i++) {
							this.fileData[i] = result.data[this.fileKey][i].key.split('/').pop();
						}
						setTimeout(() => {
							this.i = this.fileData.length;
							// this.filesOutput.emit(this.fileData);
							this.dataSource = new MatTableDataSource(this.fileData);
						}, 50);
					}
				} else {
					Swal.fire({
						title: 'Something went wrong',
						text: `Something went wrong trying to get the attachments [${result.message}], please reload or try again.`,
						icon: 'error',
					});
				}
			},
			error: (error) => {
				console.error(`Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`);
				Swal.fire({
					icon: 'error',
					title: 'Something went wrong',
					text: `Something went wrong with the server trying to get the attachments [${error?.error?.message}], please reload or try again.`,
				});
			}
		});
	}

	uploadFile_OMG(payloadData: any) {
		this.reportSubmissionService
			.uploadFile(this.submissionId, this.definition.key, payloadData.file)
			.subscribe({
				next: (result: any) => {
					if (result.success) {
						//Upload file to pre-signed url for S3
						this.http.put(result.data.url, payloadData.data).subscribe({
							next: (res_file: any) => {
								Swal.close();
								// this.fileData[this.i] = result.data.fileName;
								this.fileData[this.i] = payloadData.file.name;
								this.dataSource = new MatTableDataSource(this.fileData);
								this.i += 1;
								//Event Output
								this.filesOutput.emit(this.fileData);
							},
							error: (error_file: any) => {
								Swal.close();
								console.error('Couldn\'t upload file', error_file);
							}
						});
					} else {
						Swal.close();
						Swal.fire({
							title: 'Something went wrong',
							text: `Something went wrong trying to upload the attachment [${result.message}], please reload or try again.`,
							icon: 'error',
							heightAuto: false,
						});
					}
				}, error: (error) => {
					console.error(`Something went wrong with the server trying to upload the attachment [${error}], please reload or try again.`);
					Swal.fire({
						icon: 'error',
						title: 'Something went wrong',
						text: `Something went wrong with the server trying to upload the attachment [${error?.error?.message}], please reload or try again.`,
						heightAuto: false,
					});
				}
			});
	}

	deleteFile_OMG(payloadData: any) {
		this.reportSubmissionService.deleteFile(this.submissionId, payloadData.fileKey, payloadData.fileIndex).subscribe({
			next: (result: any) => {
				if (result.success) {
					Swal.close();
					this.fileData.splice(payloadData.fileIndex, 1);
					this.i -= 1;
					this.dataSource = new MatTableDataSource(this.fileData);
				} else {
					Swal.close();
					Swal.fire({
						title: 'Something went wrong',
						text: `Something went wrong trying to delete the attachment [${result.message}], please reload or try again.`,
						icon: 'error',
						heightAuto: false,
					});
				}
			},
			error: (error) => {
				console.error(`Something went wrong with the server trying to delete the attachment [${error?.error?.message}], please reload or try again.`);
				Swal.fire({
					icon: 'error',
					title: 'Something went wrong',
					text: `Something went wrong with the server trying to delete the attachment [${error?.error?.message}], please reload or try again.`,
					heightAuto: false,
				});
			}
		});
	}

	downloadFile_OMG(payloadData: any) {
		this.reportSubmissionService.downloadFile(this.submissionId, payloadData.fileKey, payloadData.fileIndex).subscribe((result: any) => {
			if (result.success) {
				// this.processFileDownload(payloadData, result);
				const dwldLink = document.createElement('a');
				const url = result.data;
				const isSafariBrowser =
						  navigator.userAgent.indexOf('Safari') !== -1 &&
						  navigator.userAgent.indexOf('Chrome') === -1;
				if (isSafariBrowser) {
					// if Safari open in new window to save file with random filename.
					dwldLink.setAttribute('target', '_blank');
				}
				dwldLink.setAttribute('href', url);
				dwldLink.setAttribute('download', payloadData.fileName);
				dwldLink.style.visibility = 'hidden';
				document.body.appendChild(dwldLink);
				dwldLink.click();
				document.body.removeChild(dwldLink);
			} else {
				this.utilsService.errorAlert('Couldn\'t obtain file');
			}
		}, (err) => {
			this.utilsService.errorAlert(err);
		});
	}

	downloadFile_OMG_Admin(payloadData: any) {
		this.reportMgmtService.downloadFileUrl(this.submissionId, payloadData.fileKey, payloadData.fileIndex).subscribe((result) => {
			if (result.success && isDefined(result?.data?.url)) {
				// this.processFileDownload(payloadData, result);
				const dwldLink = document.createElement('a');
				const url = result.data.url;
				const isSafariBrowser =
						  navigator.userAgent.indexOf('Safari') !== -1 &&
						  navigator.userAgent.indexOf('Chrome') === -1;
				if (isSafariBrowser) {
					// if Safari open in new window to save file with random filename.
					dwldLink.setAttribute('target', '_blank');
				}
				dwldLink.setAttribute('href', url);
				dwldLink.setAttribute('download', payloadData.fileName);
				dwldLink.style.visibility = 'hidden';
				document.body.appendChild(dwldLink);
				dwldLink.click();
				document.body.removeChild(dwldLink);
			} else {
				this.utilsService.errorAlert('Couldn\'t obtain file');
			}
		}, (err) => {
			this.utilsService.errorAlert(err);
		});
	}

	processFileDownload(payloadData: any, result: any) {
		//// Direct Download
		const file = new Blob([result], {type: `application/${payloadData.fileType}`});
		// IE11 & Edge
		const nav = (window.navigator as any);

		// To open [PDF and Images] in other tab
		let blob = file;
		let recognized = true;
		const tempStringArray = payloadData.fileName.toString().split('.');
		switch (tempStringArray[tempStringArray.length - 1].toLowerCase()) {
			case 'jpg':
			case 'jpeg':
				blob = file?.slice(0, file?.size, 'image/jpeg');
				break;
			case 'png':
				blob = file?.slice(0, file?.size, 'image/png');
				break;
			case 'pdf':
				blob = file?.slice(0, file?.size, 'application/pdf');
				break;
			default:
				recognized = false;
				break;
		}

		if (recognized) {
			const url = window.URL.createObjectURL(blob);
			let openFile = document.createElement('a');
			openFile.href = url;
			openFile.target = '_blank';
			openFile.click();
		}
		// If file isn't a pdf or image it has to be downloaded
		else {
			if (nav.msSaveOrOpenBlob) {
				nav.msSaveOrOpenBlob(file, payloadData.fileName);
			}
			// Others
			else {
				const dwldLink = document.createElement('a');
				const url = URL.createObjectURL(file);
				const isSafariBrowser =
						  navigator.userAgent.indexOf('Safari') !== -1 &&
						  navigator.userAgent.indexOf('Chrome') === -1;
				if (isSafariBrowser) {
					// if Safari open in new window to save file with random filename.
					dwldLink.setAttribute('target', '_blank');
				}
				dwldLink.setAttribute('href', url);
				dwldLink.setAttribute('download', payloadData.fileName);
				dwldLink.style.visibility = 'hidden';
				document.body.appendChild(dwldLink);
				dwldLink.click();
				document.body.removeChild(dwldLink);
			}
		}
	}

	protected readonly getIndex = getIndex;
}
